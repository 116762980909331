import React from "react";
import { Hero } from "components";
import { graphql } from "gatsby";
import SEO from "../components/SEO";

const NotFoundPage = (props) => {
  const { data, location } = props;
  const { wordpressWpSettings, siteSettings } = data;
  const { title, wordpressUrl } = wordpressWpSettings;
  const { options = null } = siteSettings;
  return (
    <>
      <SEO title={`404 | ${title}`} location={location} />
      <Hero
        eyebrow="404"
        heading={`The page "${location.pathname}" cannot be found.`}
      />
    </>
  );
};

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    wordpressWpSettings {
      title
      wordpressUrl
    }
    siteSettings: wordpressAcfOptions {
      options {
        customCss
      }
    }
  }
`;
